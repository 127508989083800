import styled from 'styled-components'
import { Box } from 'rebass'
import { above } from '../theme'

const Hero = styled(Box)`
  background-color: ${props => props.bg};
  overflow: hidden;
  position: relative;
  ${above.md`
    margin-bottom: 5em;
 `}
  h1 {
    font-weight: ${props => props.theme.fontWeights.bold};
    margin-bottom: 0.25em;
  }
  p {
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.fontSizes[4]};
  }
`

Hero.defaultProps = {
  bg: 'gray.800',
  color: 'white',
}

export default Hero
