import React from 'react'
import styled from 'styled-components'
import { Box } from 'rebass'

const OverlayStyled = styled(Box)`
  background-color: ${props => props.bg};
  opacity: ${props => props.opacity};
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`
function Overlay(props) {
  return <OverlayStyled bg={props.bg} opacity={props.opacity} />
}

Overlay.defaultProps = {
  bg: 'black',
  opacity: '0.5',
}

Overlay.displayName = 'Overlay'

export default Overlay
