import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import { Box, Flex } from 'rebass'
import { MdArrowDownward } from 'react-icons/md'
import { Link as LinkTo, animateScroll as scroll } from 'react-scroll'
import styled from 'styled-components'

import Layout from '../components/layout/Layout'
import Container from '../components/layout/Container'
import Section from '../components/layout/Section'
import SEO from '../components/elements/SEO'
import Hero from '../components/elements/Hero'
import Overlay from '../components/elements/Overlay'
import { above, colors } from '../components/theme'

const AboutHero = styled(Section)`
  background-color: ${props => props.theme.colors.primary[500]};
  color: ${props => props.theme.colors.white};
  padding: 10em 0 6em 0;
`

const About = ({ data }) => {
  const page = data.prismicAboutpage.data

  return (
    <Layout>
      <SEO
        meta_title={page.meta_title}
        meta_description={page.meta_description}
      />

      <AboutHero>
        <Container>
          <Flex flexWrap="wrap" mx={-8}>
            <Box width={[1, 1, 1 / 2]} px={8}>
              <h1>{page.title.text}</h1>
            </Box>

            <Box width={[1, 1, 1 / 2]} px={8}>
              <div
                dangerouslySetInnerHTML={{
                  __html: page.content.html,
                }}
              />
            </Box>
          </Flex>
        </Container>
      </AboutHero>
      <Hero>
        <BackgroundImage Tag="div" fluid={page.hero_image.fluid}>
          <Container
            py={16}
            css={`
              position: relative;
              display: block;
              z-index: 10;
            `}
          >
            <Flex flexWrap="wrap">
              <Box width={[1, 1, 4 / 5]}>
                <h3>{page.hero_text.text}</h3>
              </Box>
            </Flex>
          </Container>
          <LinkTo to="leadership" spy smooth offset={-70} duration={500}>
            <MdArrowDownward
              css={`
                color: ${colors.white};
                cursor: pointer;
                position: absolute;
                bottom: 2em;
                display: block;
                margin: 0 auto;
                width: 100%;
                font-size: 2em;
                z-index: 10;
              `}
            />
          </LinkTo>
          <Overlay />
        </BackgroundImage>
      </Hero>

      <Section id="leadership">
        <Container>
          <Flex flexWrap="wrap" mx={-8}>
            <Box width={1} px={8}>
              <h2>Leadership</h2>
            </Box>
          </Flex>
          {page.leadership.map(({ bio, full_name, photo, role }) => (
            <Flex flexWrap="wrap" mx={-8} key={role.text}>
              <Box width={[1, 1 / 3, 1 / 2]} px={8} mb={[8]}>
                <Img fluid={photo.fluid} />
              </Box>
              <Box width={[1, 2 / 3, 1 / 2]} px={8}>
                <h3
                  css={`
                    margin-bottom: 0;
                  `}
                >
                  {full_name.text}
                </h3>
                <h4>{role.text}</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: bio.html,
                  }}
                />
              </Box>
            </Flex>
          ))}
        </Container>
      </Section>
    </Layout>
  )
}

export default About

export const query = graphql`
  query AboutQuery {
    prismicAboutpage {
      data {
        title {
          text
        }
        content {
          html
        }
        hero_text {
          html
          text
        }
        hero_image {
          alt
          fluid(maxWidth: 1500) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }

        leadership {
          bio {
            html
            text
          }
          full_name {
            text
          }
          photo {
            alt
            fluid(maxWidth: 1500) {
              ...GatsbyPrismicImageFluid_noBase64
            }
          }
          role {
            text
          }
        }
        meta_description
        meta_title
      }
    }
  }
`
